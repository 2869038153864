html {
  height: 100%;
}
body {
  height: 100%;
  margin: 10 20 0 20;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.row {
  display: flex;
}

.column {
  display: block;
  flex: 50%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}
.bg {


  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.todos-list {
  width: 50vw;
  height: 70vh;
  padding: 2rem;
  background-color: rgb(214, 214, 247);
  overflow-y: scroll;
}

.todo {
  border-radius: 3px;
  box-shadow: 0 0.7rem 1rem rgb(93, 93, 93);
  margin-bottom: 1rem;
  background-color: rgb(106, 208, 248);
  min-height: 30%;
  color: blue;
  padding: 1rem;
}

.todo-title {
  font-size: 3rem;
}
